<template>
  <div>
    <App-Title titulo="Central de Notificações" />
    <b-loading is-full-page :active="notificationsLoading" can-cancel></b-loading>

    <div class="columns">
      <div
        class="column"
        :class="{
          'is-10 is-offset-1': customSize,
          ' is-three-fifths is-offset-one-fifth': !customSize,
        }"
      >
        <section class="section">
          <article class="panel notification-panel">
            <div class="panel-heading">
              <p class="heading-title">Notificações</p>
            </div>

            <section v-if="!notifications.length" class="section">
              <div class="content has-text-centered">
                <p>Nenhuma notificação não lida.</p>
              </div>
            </section>

            <div
              class="notification-unread notification-separator"
              :class="{
                'notification-read notification-separator': notification.status === 'LIDA',
              }"
              v-for="notification in notifications"
              :key="notification.id"
              @click.stop.prevent="updateStatus(notification)"
            >
              <a class="panel-block">
                <div class="level">
                  <div class="level-left custom-level-left">
                    <b-icon
                      v-if="notification.status === 'NAO-LIDA'"
                      icon="circle-small"
                      type="is-danger"
                    ></b-icon>

                    <span
                      :class="
                        notification.status === 'NAO-LIDA' ? 'message-unread' : 'message-read'
                      "
                    >
                      {{ notification.message }}
                    </span>

                    <div
                      class="action-buttons-container"
                      v-if="notification.type === 'CONVITE' && notification.pending"
                    >
                      <button
                        class="button is-primary action-buttons"
                        :disabled="disable.accept"
                        @click.prevent.stop="acceptOrRefuseInvite(notification, 'accept')"
                      >
                        Aceitar
                      </button>

                      <button
                        class="button is-danger"
                        :disabled="disable.refuse"
                        @click.prevent.stop="acceptOrRefuseInvite(notification, 'refuse')"
                      >
                        Recusar
                      </button>
                    </div>
                  </div>

                  <div class="level-right custom-level-right">
                    <b-tag type="is-light">{{ notification.createdAt | formatDate }}</b-tag>
                  </div>
                </div>
              </a>
            </div>
          </article>

          <div
            class="footer-container is-flex column-direction-mobile"
            :class="{ 'footer-container-flex-end': !unreadNotifications.length }"
            v-if="notifications.length"
          >
            <b-button
              v-if="unreadNotifications.length"
              class="mark-as-read-button"
              :class="{ 'rm-2': !mobile }"
              icon-left="notification-clear-all"
              type="is-primary"
              @click.stop.prevent="setNotificationsAsRead()"
            >
              Marcar todas como lidas
            </b-button>

            <AppPagination
              :data-filters="dataFilters"
              :total="notificationsTotal"
              :number-lines="numberOfLines"
              @change-page="page => filterNotifications({ page })"
              @change-per-page="perPage => changePerPageNotifications(perPage)"
            />
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import { formatarData as formatDate } from '@/utils/format';

import AppTitle from '@/components/AppTitulo';
import AppPagination from '@/components/AppPagination';

export default {
  name: 'NotificationsList',
  components: {
    AppTitle,
    AppPagination,
  },
  data() {
    const disable = {
      accept: false,
      refuse: false,
    };

    return {
      disable,
      numberOfLines: 10,
    };
  },
  filters: {
    formatDate,
  },
  computed: {
    ...mapState('app', ['windowWidth']),
    ...mapState('companies', ['company']),
    ...mapState('notifications', [
      'dataFilters',
      'notifications',
      'notificationsLoading',
      'notificationsTotal',
      'unreadNotifications',
    ]),
    ...mapGetters('app', { mobile: 'mobile' }),
    ...mapGetters({
      user: 'getUser',
    }),
    customSize() {
      return this.windowWidth >= 769 && this.windowWidth <= 961;
    },
  },
  methods: {
    ...mapActions(['chooseCompanyUser', 'fetchUser', 'login']),
    ...mapActions('notifications', [
      'changePerPageNotifications',
      'cleanFiltersNotifications',
      'filterNotifications',
      'fetchUserNotifications',
      'readNotification',
      'setNotificationsAsRead',
      'updateUserInvitation',
    ]),
    async acceptOrRefuseInvite(notification, action) {
      const accepted = action === 'accept';
      const confirmation = await this.$confirmacao({
        message: `Você tem certeza que deseja ${accepted ? 'aceitar' : 'recusar'} o convite?`,
        cancelText: 'Cancelar',
        confirmText: 'Confirmar',
        type: `${accepted ? 'is-primary' : 'is-danger'}`,
        hasIcon: true,
      });
      if (!confirmation) {
        return;
      }

      try {
        const data = {
          action,
          companyUserId: notification.informations.empresaUsuarioId,
          notificationId: notification.id,
          userId: notification.userId,
        };

        await this.updateUserInvitation(data);
        await this.filterNotifications({ status: 'NAO-LIDA', perPage: 5 });
        this.cleanFiltersNotifications();
        if (accepted) {
          if (!this.user.empresas.length) {
            await this.login({ chooseCompany: true, companyId: notification.companyId });
          } else {
            await this.fetchUser(notification.userId);
          }
        }
        this.$alerta(`Convite ${accepted ? 'aceito' : 'recusado'}`, 'is-success');
      } catch (error) {
        if (
          error.response.status === 400 &&
          error.response.data.message === 'ValidationError' &&
          error.response.data.details.message === 'Convite não existente'
        ) {
          this.$alerta('O convite não foi encontrado', 'is-danger');
          return;
        }

        this.$alerta(`Erro ao ${accepted ? 'aceitar' : 'recusar'} convite`, 'is-danger');
      }
    },
    async updateRoute(notification) {
      if (notification.action && this.$route.path !== notification.action) {
        if (notification.companyId !== this.company.id) {
          this.chooseCompanyUser(false);
          await this.login({ chooseCompany: true, companyId: notification.companyId });
        }

        this.$router.push({
          path: `${notification.action}`,
        });
        this.chooseCompanyUser(false);
      }
    },
    async updateStatus(notification) {
      this.updateRoute(notification);

      const unread = this.notifications.some(
        element => element.id === notification.id && element.status === 'NAO-LIDA',
      );
      if (unread) {
        await this.readNotification(notification.id);
        await this.filterNotifications({ status: 'NAO-LIDA', perPage: 5 });
        this.cleanFiltersNotifications();
      }
    },
  },
  async created() {
    await this.fetchUserNotifications({ page: 1, perPage: 10 });
  },
};
</script>

<style lang="scss" scoped>
.action-buttons {
  margin-left: 1.5rem;
  margin-right: 0.5rem;
}

.action-buttons-container {
  display: flex;
}

.custom-level-left {
  display: flex;
  flex-basis: 80%;
  min-width: 0;
  word-break: break-word;
}

.custom-level-right {
  display: flex;
  flex-basis: 20%;
  min-width: 0;
  word-break: break-word;
}

.footer-container {
  justify-content: space-between;
  margin-bottom: 2rem;
}

.footer-container-flex-end {
  justify-content: flex-end;
}

.mark-as-read-button {
  margin-top: 2rem;
}

.message-unread {
  margin-left: 0.2rem;
  cursor: pointer;
}

.message-read {
  margin-left: 1.8rem;
  cursor: pointer;
}

.notification-panel {
  font-size: 0.9rem;
  margin-bottom: unset;
}

.notification-unread {
  background-color: #e6e6e6;
}

.notification-read {
  background-color: #ffffff;
}

.notification-separator {
  width: 100%;
  border-top: 1px solid rgba(165, 165, 165, 0.7);
}

.panel-block {
  display: block;
}

.rm-2 {
  margin-right: 2rem;
}

@media (min-width: 769px) and (max-width: 1100px) {
  .custom-level-left {
    flex-basis: 75%;
  }

  .custom-level-right {
    flex-basis: 20%;
  }
}
</style>
